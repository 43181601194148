export const categories = [
    {
        name: 'Music',
        image: 'categories/music.jpg'
    },
    { 
        name: 'Creating',
        image: 'categories/creating.jpg'
    },
    {
        name: 'Expressions',
        image: 'categories/expressions.jpg'
    },
    {
        name: 'Movement',
        image: 'categories/dance-movement.jpg'
    },
    
    {
        name: 'Experiments',
        image: 'categories/experiments.jpg'
    },
    
];