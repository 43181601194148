import React from 'react';
import styles from "./faq.module.css";
function Header()
{
    return (
        <>
            <div className={styles.logo}></div>
            <div className={styles.amielogo}></div>
            <div className={styles.erasmuslogo}></div>
            <header>
                <div className={styles.popo}>FAQ Categories</div>
                <article>
                </article>
            </header>

        </>


    )
}

export default Header;